import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);
const store = new Vuex.Store({
  state: {
    ifRipple: false,
    tabActive: sessionStorage.getItem('tabActive') || 0, // 底部导航
    applyTab: sessionStorage.getItem('applyTab') || 0, // 红人申请导航
    leaveTab: sessionStorage.getItem('leaveTab') || 0, // 离开申请导航
    token: sessionStorage.getItem('token') || ``,
    loading: false
  },
  mutations: {
    setToken (state, str) {
      state.token = str;
    },
    setTabActive (state, str) {
      state.tabActive = str;
    },
    setApplyTab (state, str) {
      state.applyTab = str;
    },
    setLeaveTab (state, str) {
      state.leaveTab = str;
    },
    showLoading (state) {
      state.loading = true;
    },
    hideLoading (state) {
      state.loading = false;
    }
  },
  getters: {
  },
  actions: {
  }
});
export default store;

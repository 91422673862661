<template>
  <div id="app">
  <!--    不被缓存的页面-->
    <router-view/>
    <!-- 底部导航展示 -->
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
  },
  data () {
    return {
    };
  },
  computed: {

  },
  mounted () {
  },
  watch: {
    $route: {
      handler: function(){
      },
      // 深度观察监听
      deep: true
    },
  },
  beforeCreate() {
  },
  created () {
    if (this.isMobile()) {
      window.location.href = 'http://www.autewifi.com/h5'
    } else {
      // alert(1)
      // window.location.href = 'http://www.autewifi.com'
    }
  },
  methods: {
    isMobile() {   //判断是否为移动端
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      return /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile/.test(userAgent);
    },
  }
};
</script>
<style src="./assets/css/index.scss" lang="scss"></style>
<style src="./assets/css/page.scss" lang="scss"></style>
<!--<style src="./assets/css/theme.css"></style>-->
<style lang="scss" scoped>
#app {
  height: 100%;width: 100%;
  >div{overflow-x: hidden;}
}
</style>

import 'babel-polyfill';
import Vue from 'vue';
import Es6Promise from 'es6-promise';
import App from './App';
import store from './store/store';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import 'bootstrap/dist/js/bootstrap.js'

import scroll from 'vue-seamless-scroll'
Vue.use(scroll)
import router from './router';
import '@/http/http.js';
import VueAnimateNumber from 'vue-animate-number'
Vue.use(VueAnimateNumber)

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
Vue.use(VueSlickCarousel)
Vue.component('VueSlickCarousel', VueSlickCarousel) //全局注册组件

Vue.config.productionTip = false;
Vue.use(ElementUI);
Es6Promise.polyfill();
let vueApp;
InitApp();
function InitApp () {
  /* eslint-disable no-new */
  vueApp = new Vue({
    el: '#app',
    router,
    store,
    template: '<App/>',
    components: { App }
  });
}
// 设置浏览器标题
Vue.directive('title', {
	inserted: function (el) {
		document.title = el.dataset.title
		}
})
export {vueApp};

import Vue from 'vue';
import axios from 'axios';
// import Qs from 'qs';
import store from '../store/store';
import VueAxios from 'vue-axios';
import Router from '../router/index';

Vue.use(VueAxios, axios);
axios.defaults.baseURL = window.serverConfig.VUE_APP_API_BASE_URL;
axios.defaults.withCredentials = false;
axios.defaults.headers.post['Content-Type'] = 'application/json';
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers['authorization'] = (sessionStorage.getItem('token')) || '';
axios.defaults.timeout = 600000;
axios.defaults.transformRequest = function (data, headers) {
  if (headers['Content-Type'] === 'multipart/form-data') {
    return data;
  } else {
    // return Qs.stringify(data);
    return JSON.stringify(data);
  }
};
/**
 * 请求拦截器
 * **/
axios.interceptors.request.use(function (config) {
  return config;
}, function (error) {
  console.log('请求出错了！');
  return Promise.reject(error);
});

/**
 * 响应拦截器
 * **/
axios.interceptors.response.use(function (response) {
  store.commit('hideLoading');
  if (response.data.code === 401) {
    Router.replace({name: 'login'}).catch(() => true);
  }
  // eslint-disable-next-line no-empty
  if (response.data.code === 500) {
  }
  return response;
}, function (error) {
  console.log(error);
  // eslint-disable-next-line no-empty
  if (error.response.status === 401) {
  }
  if (error.response.status === 403) {
    sessionStorage.removeItem('realName');
  }
  // eslint-disable-next-line no-empty
  if (error.response.status === 500) {}
  return Promise.reject(error);
});

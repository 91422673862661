import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/home.vue') // 首页
    },
    {
      path: '/plan1',
      name: 'plan1',
      component: () => import('@/views/plan/plan1.vue') // 解决方案   1
    },
    {
      path: '/plan2',
      name: 'plan2',
      component: () => import('@/views/plan/plan2.vue') // 解决方案   2
    },
    {
      path: '/plan3',
      name: 'plan3',
      component: () => import('@/views/plan/plan3.vue') // 解决方案   3
    },
    {
      path: '/plan4',
      name: 'plan4',
      component: () => import('@/views/plan/plan4.vue') // 解决方案   4
    },
    {
      path: '/plan5',
      name: 'plan5',
      component: () => import('@/views/plan/plan5.vue') // 产品服务   5
    },
    {
      path: '/product1',
      name: 'product1',
      component: () => import('@/views/product/product1.vue') // 产品服务   1
    },
    {
      path: '/product2',
      name: 'product2',
      component: () => import('@/views/product/product2.vue') // 产品服务   2
    },
    {
      path: '/product3',
      name: 'product3',
      component: () => import('@/views/product/product3.vue') // 产品服务   3
    },
    {
      path: '/product4',
      name: 'product4',
      component: () => import('@/views/product/product4.vue') // 产品服务   4
    },
    {
      path: '/product5',
      name: 'product5',
      component: () => import('@/views/product/product5.vue') // 产品服务   4
    },
    {
      path: '/fuse',
      name: 'fuse',
      component: () => import('@/views/fuse.vue') // 产品融合
    },
    {
      path: '/cooperation',
      name: 'cooperation',
      component: () => import('@/views/cooperation.vue') // 生态合作
    },
    {
      path: '/news',
      name: 'news',
      component: () => import('@/views/news.vue') // 新闻列表
    },
    {
      path: '/newsDetails',
      name: 'newsDetails',
      component: () => import('@/views/newsDetails.vue') // 新闻详情
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('@/views/about.vue') // 关于我们
    },
  ]
});

//获取原型对象上的push函数
const originalPush = Router.prototype.push
//修改原型对象中的push方法
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
